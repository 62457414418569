:local(.wrapper) {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: margin 0.5s;
  background-color: #1a1a1a; }

:local(.wrapper.is-nav-open) {
  margin-left: 0; }

:local(.nav) {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #263238;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

:local(.nav__icon) {
  position: absolute;
  top: 10px;
  right: -40px;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s; }

:local(.nav__icon:hover) {
  color: #5eb2ff; }

:local(.nav__body) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }

:local(.container) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #1a1a1a; }

:local(.liveWindow) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 25%; }

:local(.paper) {
  margin: 2;
  position: relative;
  background-color: #2e2e2e;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: none; }

:local(.livePaper) {
  margin: 60% auto 0 auto;
  position: relative;
  background-color: #2e2e2e;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: none; }

:local(.wings) {
  background-color: #1a1a1a;
  border: none;
  border-radius: 0; }
  :local(.wings) h3 {
    display: flex;
    justify-content: center;
    align-content: center; }

:local(.liveWings) {
  margin: 60% auto 0 auto;
  background-color: #1a1a1a;
  border: none;
  border-radius: 0; }
  :local(.liveWings) h3 {
    display: flex;
    justify-content: center;
    align-content: center; }

:local(.action) {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 2em;
  position: absolute;
  bottom: 10px; }

:local(.supportButton) {
  z-index: 1;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: x-small;
  height: 6%;
  max-width: 4%;
  left: 27.5%;
  top: 15%; }

:local(.liveButton) {
  z-index: 1;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: x-small;
  height: 6%;
  max-width: 6%;
  left: 20%;
  top: 15%; }

:local(.sendButton) {
  width: 8em;
  height: 50%;
  margin: 0 2em;
  background-color: #0091ea;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s; }

:local(.sendButton:disabled) {
  background-color: #555; }

:local(.sendButton:not(:disabled):hover) {
  background-color: #007bb5; }

:local(.messageInput) {
  flex-grow: 1;
  background-color: #424242;
  border-radius: 4px; }

:local(.messageContainer) {
  overflow-y: auto;
  height: calc(100% - 60px);
  margin-bottom: 1em;
  padding: 1em;
  background-color: #1a1a1a;
  border-radius: 4px; }

:local(.li) {
  text-decoration: none;
  list-style-type: none;
  border: none; }

:local(.ul) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style-type: none;
  padding-inline-end: 40px;
  margin: 1em; }

:local(.message) {
  list-style: none;
  display: flex;
  flex-direction: column; }

:local(.owner) {
  display: flex;
  align-self: flex-end;
  background-color: #0091EA;
  color: #FFF;
  padding: 0.5em 1.5em;
  border-radius: 20px;
  margin: 0.5em 0 0.5em auto;
  word-break: break-word;
  max-width: 65%;
  width: fit-content; }

:local(.guest) {
  display: flex;
  align-self: flex-start;
  background-color: #8BC34A;
  color: #FFF;
  padding: 0.5em 1.5em;
  border-radius: 20px;
  margin: 0.5em 0;
  word-break: break-word;
  max-width: 65%;
  width: fit-content; }
