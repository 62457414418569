:local(.app) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center; }

:local(.body) {
  margin: auto 25%;
  width: 100%; }

:local(.selector) {
  display: flex;
  justify-content: center;
  min-width: fit-content; }

:local(.main) {
  margin: auto; }
  @media (max-width: 768px) {
    :local(.main) {
      margin: auto 15%; } }

:local(.table) {
  display: block;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }
  :local(.table) .thead {
    display: table;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1; }
    :local(.table) .thead tr th {
      padding: 16px;
      text-align: left;
      font-weight: bold;
      font-size: 14px;
      border-bottom: 2px solid #ddd; }
      :local(.table) .thead tr th.logo {
        width: 15%; }
      :local(.table) .thead tr th.names {
        width: 25%; }
      :local(.table) .thead tr th.wallet {
        margin-left: 2.5%;
        width: 30%; }
      :local(.table) .thead tr th.scores {
        width: 10%;
        text-align: center; }
  :local(.table) .tbody {
    display: block;
    overflow-y: auto;
    overflow-x: auto; }
    :local(.table) .tbody > Tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      transition: background-color 0.3s ease; }
      :local(.table) .tbody > Tr:hover {
        background-color: #f9f9f9; }
      :local(.table) .tbody > Tr td {
        overflow-y: scroll;
        overflow-x: scroll;
        padding: 12px;
        font-size: 14px;
        vertical-align: middle;
        border-bottom: 1px solid #eee; }
        :local(.table) .tbody > Tr td.logo {
          text-align: center; }
          :local(.table) .tbody > Tr td.logo img {
            max-width: 40px;
            max-height: 40px;
            border-radius: 50%; }
        :local(.table) .tbody > Tr td.names {
          font-weight: bold;
          text-align: left; }
        :local(.table) .tbody > Tr td.wallet {
          margin-left: 5%;
          margin-right: 10%;
          text-align: left; }
        :local(.table) .tbody > Tr td.scores {
          text-align: center; }
  @media (max-width: 768px) {
    :local(.table) {
      margin: auto 25%;
      display: inline-table; }
      :local(.table) .thead,
      :local(.table) .tbody {
        display: table;
        width: max-content;
        overflow-x: scroll;
        overflow-y: scroll;
        padding: auto 5%; }
      :local(.table) .thead > tr > th.names {
        padding: auto 5%; }
      :local(.table) .tbody {
        max-height: unset; }
        :local(.table) .tbody > Tr {
          width: max-content; }
          :local(.table) .tbody > Tr td.names {
            text-align: center;
            padding: auto 25%; }
            :local(.table) .tbody > Tr td.names img {
              max-width: 100%;
              max-height: 100%;
              border-radius: 50%; } }

:local(.detailsCell) {
  text-align: center; }
  :local(.detailsCell) .button {
    background-color: #a0a9b0;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease; }
    :local(.detailsCell) .button:hover {
      background-color: #8a9298; }
