:local(.item) {
  padding: 0px 20px;
  margin: 20px 0px;
  display: flex;
  height: auto;
  flex-direction: column; }
  :local(.item) > .titles {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 26px;
    color: #8c969f; }
  :local(.item) > .content {
    margin: 0 auto;
    font-size: 30px;
    line-height: 35px;
    height: fit-content;
    color: #25292d; }
  :local(.item).small {
    padding: 0px 10px;
    margin: 10px 0px; }
    :local(.item).small > .content {
      font-size: 22px;
      line-height: 25spx; }
