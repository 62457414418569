:local(.button) {
  font-family: "Roboto", sans-serif;
  text-align: center;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: #3bcf5d;
  padding: 12px 18px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  transition: all 150ms; }
  :local(.button).search {
    display: inline;
    text-align: center;
    color: #ff7d38;
    background-color: #fff;
    min-width: inherit;
    height: 36px;
    border: 1px solid #dfe1e2; }
  @media only screen and (max-width: 600px) {
    :local(.button) {
      padding: 12px 6px; } }
  :local(.button):hover {
    box-shadow: 1px 1px 8px #888888; }
  :local(.button).wide {
    width: 100%;
    border-radius: 0px; }
  :local(.button).trial {
    background-color: #413a3a;
    padding: 5px 1rem;
    height: 100%; }
  :local(.button).large {
    padding: 14px 24px; }
    @media only screen and (max-width: 600px) {
      :local(.button).large {
        padding: 14px 12px; } }
  :local(.button).times {
    width: 100%;
    margin: 0; }
  :local(.button).verify {
    background-color: #413a3a;
    padding: 0px 1rem;
    font-size: x-small; }
  :local(.button).rx {
    background: lightgrey;
    color: #888888;
    font-size: 1em; }
  :local(.button).cancel {
    color: #888;
    background: #fff;
    border: 1px solid #eee;
    width: 100px; }
  :local(.button).search {
    color: #ff7d38;
    background-color: #fff;
    min-width: inherit;
    height: 36px;
    border: 1px solid #dfe1e2; }
  :local(.button).module {
    width: 15%;
    height: 20rem;
    background-color: #413a3a;
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 1.5rem;
    margin: .1px;
    border-radius: 5%; }
    @media only screen and (max-width: 600px) {
      :local(.button).module {
        width: 25%;
        height: 10rem;
        background-color: #413a3a;
        font-family: 'Alegreya', serif;
        font-weight: 600;
        font-size: 1.5rem;
        margin: auto 10px; } }
  :local(.button).danger {
    background-color: #ef4836; }
  :local(.button).confirm {
    background-color: #0392f5; }
  :local(.button).circle {
    border-radius: 100%;
    width: fit-content;
    background-color: #52bde7; }
  :local(.button).inactive {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5; }
    :local(.button).inactive:hover {
      box-shadow: none; }
    :local(.button).inactive.verify {
      width: fit-content;
      padding: 0px 1rem;
      display: inline-flex; }
  :local(.button) > .fa {
    margin-right: 10px; }

:local(.textButton) {
  cursor: pointer;
  transition: all 150ms; }
  :local(.textButton):hover {
    text-shadow: 1px 1px 8px #b4b3b3; }
  :local(.textButton).danger {
    color: #ef4836; }
  :local(.textButton).confirm {
    color: #0392f5; }
