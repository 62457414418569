:local(.container) {
  position: relative; }
  :local(.container) > label {
    display: block;
    position: absolute;
    top: -24px; }

:local(.select) {
  min-width: 110px;
  width: 100%;
  height: 36px;
  padding: 4px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #dfe1e2;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 14px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat; }
  :local(.select).table {
    margin: auto 15px;
    padding: auto 5px;
    text-shadow: 1px 1px #fff; }
