:local(.container) {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%; }
  :local(.container) > .float-box {
    max-width: inherit;
    width: 500px; }
    :local(.container) > .float-box > p {
      text-align: center; }
    :local(.container) > .float-box > .body {
      flex: 1;
      justify-content: space-around; }
      :local(.container) > .float-box > .body > .error {
        color: red;
        margin-left: 10px; }
      :local(.container) > .float-box > .body > .button {
        width: 200px; }
