#outer-empingo-div{
    /* background: #000; */
    width: 80%;
    margin: auto;
    margin-top: 60px;
}
#outer-empingo-div a:hover{
    text-decoration:none;
}
.empinfo-button{
    /* background-color:#dfe1e2; */
    border-color:#dfe1e2;
    color:#dfe1e2;
    margin: 10px;

}
.empinfo-button:hover{
    background-color:#dfe1e2;
    /* border-color:#dfe1e2; */
    color:#fff;
    border-color:#dfe1e2;


}
.empinfo-button:active{
    background-color:#dfe1e2 !important;
    /* border-color:#dfe1e2; */
    color:#fff !important;
    border-color:#dfe1e2 !important;


}