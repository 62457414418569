:local(.header) {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  :local(.header) > select {
    max-width: 200px; }
  :local(.header) > .month {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center; }
    :local(.header) > .month > .icon {
      cursor: pointer;
      background: #fff;
      box-shadow: 0 1px 2px 0 #afafc5;
      height: 42px;
      width: 42px;
      font-size: 24px;
      transition: 150ms; }
      :local(.header) > .month > .icon:hover {
        background-color: #eee; }
    :local(.header) > .month > .display {
      font-size: 28px;
      width: 240px;
      text-align: center; }
  :local(.header) > .button {
    flex: 1;
    display: flex;
    justify-content: flex-end; }
