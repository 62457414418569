.call-window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: -1;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  background: -webkit-linear-gradient(to bottom, #074055 0%, #030D10 100%);
  background: -moz-linear-gradient(to bottom, #074055 0%, #030D10 100%);
  background: -o-linear-gradient(to bottom, #074055 0%, #030D10 100%);
  background: linear-gradient(to bottom, #074055 0%, #030D10 100%); }
  .call-window.active {
    opacity: 1;
    z-index: auto; }
    .call-window.active .video-control {
      z-index: auto;
      -webkit-animation: in-fadeout 3s ease;
      -moz-animation: in-fadeout 3s ease;
      -o-animation: in-fadeout 3s ease;
      animation: in-fadeout 3s ease; }
  .call-window .video-control {
    position: absolute;
    bottom: 20px;
    height: 72px;
    width: 100%;
    text-align: center;
    opacity: 0;
    z-index: -1;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s; }
    .call-window .video-control:hover {
      opacity: 1; }
  .call-window video {
    position: absolute; }
  .call-window #localVideo {
    bottom: 0;
    left: 0;
    width: 20%;
    height: 20%; }
  .call-window #peerVideo {
    width: 100%;
    height: 100%; }

@-webkit-keyframes in-fadeout {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes in-fadeout {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-o-keyframes in-fadeout {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes in-fadeout {
  0% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.video-control .btn-action, .call-modal .btn-action {
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin: 0px 8px;
  font-size: 1.4em;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: 0.25s; }
  .video-control .btn-action:hover, .call-modal .btn-action:hover {
    opacity: 0.8; }
  .video-control .btn-action.hangup, .call-modal .btn-action.hangup {
    background-color: #E81123;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  .video-control .btn-action:not(.hangup), .call-modal .btn-action:not(.hangup) {
    background-color: #00AFF0; }
    .video-control .btn-action:not(.hangup).disable, .call-modal .btn-action:not(.hangup).disable {
      background-color: #E81123; }
