:local(.body) {
  margin: 5rem auto;
  padding: auto 5rem 5rem 5rem;
  height: fit-content;
  width: fit-content; }

:local(.vBtn) {
  margin: 2rem; }

:local(.modal) {
  width: fit-content;
  height: fit-content;
  padding: auto;
  margin: auto;
  overflow: scroll; }

:local(.success) {
  text-align: center; }
  :local(.success) h2 {
    color: seagreen; }
  :local(.success) a {
    display: block; }

:local(.checkoutForm) p {
  text-align: center; }

:local(.checkoutForm) label {
  min-width: 250px;
  margin: 1px 10px;
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em; }

:local(.checkoutForm) input,
:local(.checkoutForm) .StripeElement {
  display: block;
  margin: auto 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white; }
  :local(.checkoutForm) input::placeholder,
  :local(.checkoutForm) .StripeElement::placeholder {
    color: #aab7c4; }
  :local(.checkoutForm) input:focus,
  :local(.checkoutForm) input .StripeElement--focus,
  :local(.checkoutForm) .StripeElement:focus,
  :local(.checkoutForm) .StripeElement .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease; }

:local(.checkoutForm) a {
  display: block;
  text-align: center;
  margin-top: 1em; }

:local(.orderButton) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-decoration: none;
  margin-top: 5%;
  background-color: #008cdd;
  border: 1px solid #008cdd;
  font-size: 1em;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  padding: 0.5em 1em;
  width: 200px;
  transition: opacity 200ms;
  cursor: pointer; }
  :local(.orderButton).success {
    background-color: seagreen;
    border-color: seagreen; }
  :local(.orderButton):hover {
    opacity: 0.8; }
  :local(.orderButton):disabled {
    opacity: 0.5;
    cursor: not-allowed; }

:local(.container) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 25px; }
  :local(.container) :local(.fb) {
    height: auto; }
  :local(.container) :local(.split) {
    margin: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    :local(.container) :local(.split) :local(.b1) {
      max-width: 100px;
      background-color: #dd000b;
      margin: auto 10px; }
    :local(.container) :local(.split) :local(.b2) {
      max-width: 100px;
      margin: auto 10px; }
  :local(.container) :local(.ua) * {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-top: 5px; }
    :local(.container) :local(.ua) * Input {
      width: 25%; }
