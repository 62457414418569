:local(.container) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px; }
  @media only screen and (max-width: 600px) {
    :local(.container) {
      padding: 0px; } }
  :local(.container) > h1 {
    font-weight: normal;
    text-align: center;
    flex: 1; }
  :local(.container) > h2 {
    font-weight: normal; }
  :local(.container) > h3 {
    font-weight: normal;
    text-align: center;
    flex: 1; }
  :local(.container) > .icon-header {
    display: flex;
    align-items: center; }
    :local(.container) > .icon-header > h3 {
      padding-left: 8px; }
      :local(.container) > .icon-header > h3.back {
        color: #0392f5;
        cursor: pointer; }
  :local(.container) > .buttons > .edit, :local(.container) > .buttons > .delete {
    cursor: pointer;
    width: 24px; }
  :local(.container) > .buttons > .edit {
    color: #0392f5; }
  :local(.container) > .buttons > .delete {
    color: #ef4836; }
