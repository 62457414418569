:local(.detailsCell) {
  width: 1px; }
  :local(.detailsCell) > .button {
    background-color: #a0a9b0;
    min-width: inherit;
    padding: 12px; }

:local(.select) {
  display: flex;
  justify-content: center;
  min-width: fit-content; }

:local(.head) {
  overflow: scroll; }
  :local(.head) > th {
    margin: auto; }

:local(.map) > .email {
  overflow-x: scroll; }

:local(.map) > td {
  padding: 15px; }
