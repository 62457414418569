:local(.searchBar) {
  flex: 1;
  position: relative;
  display: flex; }
  :local(.searchBar) > label {
    display: block;
    position: absolute;
    top: -24px; }
  :local(.searchBar) > input {
    width: 100%;
    min-width: 100%;
    height: 36px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #dfe1e2; }
    :local(.searchBar) > input::placeholder {
      color: #8c969f; }
    @media (max-width: 768px) {
      :local(.searchBar) > input {
        width: 100px;
        min-width: 100%; } }
  :local(.searchBar).selectable > select {
    width: auto;
    min-width: 92px;
    background-color: #f6f8fa;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none; }
  :local(.searchBar).selectable > input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
