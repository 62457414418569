:local(.header) > h2 {
  display: flex;
  align-items: baseline;
  justify-content: center; }
  :local(.header) > h2 > .group {
    font-size: 18px;
    font-weight: lighter;
    padding: 0px 10px;
    color: #aaa; }

:local(.body) {
  width: 100%;
  padding: 0px; }
  :local(.body) > .switch-buffer {
    height: 60px; }
  :local(.body) > .switch-table > .main {
    border: none; }

@media only screen and (max-width: 600px) {
  :local(.tabOptions) {
    display: flex;
    justify-content: center; } }

:local(.viewInfo) {
  margin: auto 10px; }

:local(.logoInfo) {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row; }

:local(.contactInfo) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px; }
  :local(.contactInfo) > div {
    min-height: 36px;
    display: flex;
    align-items: center; }
    :local(.contactInfo) > div:not(:last-child) {
      margin-bottom: 5px; }
    :local(.contactInfo) > div input, :local(.contactInfo) > div span, :local(.contactInfo) > div button {
      margin-left: 5px; }
  :local(.contactInfo) > img {
    margin: auto;
    width: fit-content; }

:local(.buttons) {
  margin: 15px auto;
  display: flex;
  justify-content: center;
  flex-direction: row; }
