:local(.container) {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%;
  margin: auto; }

:local(.btnReg) {
  margin-top: 10px; }

:local(.body) {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  :local(.body) > .button {
    width: 100%; }
