@import url(css/normalize.css);
@import url(css/font-awesome.css);
* {
  box-sizing: border-box; }

html {
  height: 100vh; }
  html body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    width: 100vw;
    height: 100vh;
    background-color: #f6f8fa;
    color: #666; }
    html body > div#root {
      height: 100vh;
      padding: 0; }
