html {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased; }

body, h1, h2, h3, h4, h5, h6 {
  margin: 0; }

body {
  font-size: 15px;
  line-height: 24px; }

/* content wrapper */
.content {
  margin: 5px; }

@media screen and (min-width: 768px) {
  .content {
    margin: 10px; } }

@media screen and (min-width: 992px) {
  .content {
    margin: 20px; } }

@media screen and (min-width: 1200px) {
  .content {
    margin: 30px; } }

/* Report filter */
.filter-criteria {
  display: block; }

.filter-duration {
  cursor: pointer; }

@media screen and (min-width: 768px) {
  .filter-criteria {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid #e6e6e6; }
  .filter-criteria.first {
    margin-left: inherit;
    margin-right: inherit;
    border-left: inherit;
    border-right: inherit;
    padding-left: inherit; } }

.report .row2 {
  margin-top: 50px; }

/* Tag list dialog */
.container-add-tag {
  display: inline-block; }

.container-add-tag-popup .list {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.container-add-tag-popup .list.first {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.container-add-tag-popup .list-item {
  padding-top: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 5px !important; }

.container-add-tag-popup .list-item > div > div {
  padding-top: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 5px !important; }

.container-add-tag-popup .list-item > div > div .material-icons {
  font-size: 13px !important;
  margin: auto !important;
  top: 7px !important; }

.container-add-tag-popup .input-filter {
  height: 30px; }

.time-entry-input-form .container-add-tag {
  margin-left: 10px; }

:local(.app) {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5 auto;
  align-items: center; }

@media screen and (min-width: 1200px) {
  :local(.app) {
    max-width: 1100px; } }

:local(.app) > .main {
  width: 100%;
  margin: auto;
  overflow-y: scroll; }

:local(.notifications) {
  width: 50px;
  color: blue;
  position: absolute; }
