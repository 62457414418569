* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #222222;
  height: 100vh;
  color: #fafafa; }

.App-title {
  font-size: 50px;
  padding: 25px 0; }

.Timers {
  display: flex;
  justify-content: center; }

@media (max-width: 900px) {
  .Timers {
    flex-direction: column;
    align-items: center; } }

@media (max-width: 900px) {
  .Stopwatch {
    margin-bottom: 40px; } }

.Countdown,
.Stopwatch {
  margin-left: 30px;
  margin-right: 30px;
  border: 2px solid grey;
  border-radius: 4px;
  padding: 20px;
  width: 400px;
  background-color: #161b1f;
  box-shadow: 0 3px 6px #0c0c0c; }

.Countdown {
  padding-top: 10px; }

.Countdown-header,
.Stopwatch-header {
  font-size: 40px;
  font-weight: bold; }

.Stopwatch button {
  padding: 12px 32px;
  font-size: 20px; }

.Stopwatch-display {
  padding: 40px 0;
  font-size: 48px; }

.Countdown-display {
  margin-top: 5px;
  margin-bottom: 20px; }

.Countdown-display button {
  margin: 0 15px;
  border: solid 1px transparent;
  border-radius: 2px;
  padding: 4px 16px;
  color: #ffffff;
  font-size: 16px; }

.Countdown-display button:hover {
  background-color: #fff; }

.Countdown-label {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px; }

.Countdown-time {
  font-size: 36px;
  margin: 5px 0; }
