:local(.selector) {
  display: flex;
  justify-content: center;
  min-width: fit-content; }

:local(.detailsCell) {
  width: 1px; }
  :local(.detailsCell) > .button {
    background-color: #a0a9b0;
    min-width: inherit;
    padding: 12px; }
