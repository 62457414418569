:local(.detailsCell) {
  width: 1px; }
  :local(.detailsCell) > .button {
    background-color: #a0a9b0;
    min-width: inherit;
    padding: 12px; }

tr.deleted {
  color: red; }

tr > td {
  width: 25%;
  overflow-x: scroll; }

tr > td > td.icons {
  border: none;
  bottom: 5%; }

a.active {
  font-weight: bolder;
  text-decoration: underline; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

.ui.menu .item a {
  padding-bottom: 5px !important; }

.ui.menu .item a.active {
  border-bottom: 1px solid black; }
