@import url("https://fonts.googleapis.com/css?family=Noto+Sans|Roboto:400,700");
:root {
  --ltBlue: #c4f1ff;
  --mainWhite: #f5f6f8;
  --mdBlue: #5970a7;
  --orange: #f87e40;
  --dkBlue: #000a6c;
  --gBlue: #1a73e8;
  --gaWhite: #ffffff;
  --gBlack: #1f1f1f;
  --gdkGrey: #b9b9b9;
  --gltGrey: #f1f3f4;
  --aGreen: #1de9b6;
  --adkgrey: #434343;
  --altGrey: #636363; }

html {
  box-sizing: border-box; }

.App .calculator {
  height: 555px;
  margin: 2px auto;
  border-radius: 0 0 2% 2%;
  font-family: "Roboto", sans-serif;
  /*font-family: "Noto Sans", sans-serif;*/
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.4); }

.calculator {
  position: relative;
  width: 320px;
  margin: 0 auto;
  padding: 0;
  outline: none;
  overflow: hidden;
  border: none; }

.calculator-display {
  height: 200px;
  width: 100%;
  background-color: #f5f6f8;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
  z-index: 1; }

.rad-deg {
  color: var(--mdBlue);
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: default; }

.input-display {
  color: #000a6c;
  width: 100%;
  height: 90px;
  font-size: 45px;
  text-align: right;
  padding: 15px;
  margin-top: 40px;
  overflow-y: hidden;
  line-height: 3em;
  outline: none;
  border: none;
  background-color: transparent;
  caret-color: var(--orange);
  transition: all 0.5s ease-out; }

.answer-display {
  color: var(--mdBlue);
  width: 100%;
  height: 55px;
  font-size: 30px;
  padding: 15px;
  margin-bottom: 15px;
  text-align: right;
  transition: all 0.5s ease-out;
  line-height: 1em;
  border: none;
  background-color: transparent; }

.calculator-keypad {
  width: 100%;
  margin: 0;
  padding: 0; }

.pad {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; }

.number-pad {
  background-color: #c4f1ff;
  width: 100%;
  padding-bottom: 35px;
  margin: 0;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center; }

.number-pad > button {
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 100%;
  outline: none;
  text-align: center;
  background-color: transparent;
  font-size: 2em;
  color: var(--dkBlue);
  background-position: center; }

.operators {
  background-color: var(--dkBlue);
  padding: 10px; }

.operators > button {
  width: 56px;
  height: 56px;
  outline: none;
  overflow: hidden;
  border: none;
  background-color: transparent;
  color: var(--mainWhite);
  cursor: pointer;
  border-radius: 100%;
  font-size: 1.3em; }

.extra-operators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #f87e40;
  padding: 5px;
  margin: 0;
  height: 100%;
  width: 216px;
  position: absolute;
  right: -188px;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }

.extra-operators > button {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 5px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 1em;
  color: #000a6c;
  background-color: transparent;
  border-radius: 100%; }

/*Button Ripple effects*/
.ripple {
  background-position: center;
  transition: background 0.8s; }

.ripple:hover {
  background: #f75909 radial-gradient(circle, transparent 1%, #f75909 1%) center/15000%; }

.ripple:active {
  background-color: #606061;
  background-size: 100%;
  transition: background 0s; }

.ripple-numpad {
  background-position: center;
  transition: background 0.8s; }

.ripple-numpad:hover {
  background: #8ee4ff radial-gradient(circle, transparent 1%, #8ee4ff 1%) center/15000%; }

.ripple-numpad:active {
  background-color: #606061;
  background-size: 100%;
  transition: background 0s; }

.ripple-oper {
  background-position: center;
  transition: background 0.8s; }

.ripple-oper:hover {
  background: #0010a3 radial-gradient(circle, transparent 1%, #0010a3 1%) center/15000%; }

.ripple-oper:active {
  background-color: #606061;
  background-size: 100%;
  transition: background 0s; }

/*Button Ripple effects*/
.drag-sym {
  position: absolute;
  top: 35%;
  left: -15px;
  color: var(--mainWhite);
  cursor: pointer; }

/*Menu */
.menu-icon {
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  color: var(--mdBlue); }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--mainWhite);
  min-width: 160px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  right: 5px;
  top: 2px;
  z-index: 1;
  font-size: 1em;
  text-transform: capitalize; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

li {
  margin: 0;
  padding: 5px;
  color: black;
  font-size: 0.8em; }

/*Menu */
/*History Section*/
.top-pane {
  position: relative;
  width: 100%;
  height: 10vh;
  color: var(--mainWhite);
  background-color: var(--orange);
  -webkit-box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  z-index: 1; }

.backButton {
  position: absolute;
  left: 15px;
  top: 20px; }

.title {
  position: absolute;
  left: 55px;
  top: 20px; }

.historySec {
  text-align: right;
  margin: 5px 20px; }

.historyVal {
  margin-bottom: 50px; }

.historyExp {
  font-size: 1.5em;
  color: #000; }

.historyResults {
  color: #fff;
  font-size: 1.7em;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }

.setting-sec {
  margin: 25px auto;
  padding: 5px; }

.inverseButton {
  background-color: "#f79c6f";
  border-radius: 10%; }

/*Google Theme*/
.ripple-google {
  color: var(--gBlack);
  background-position: center;
  transition: background 0.8s; }

.ripple-google:hover {
  background: rgba(0, 0, 0, 0.1) radial-gradient(circle, transparent 1%, #e8e9ea 1%) center/15000%; }

.ripple-google:active {
  background-color: var(--gltGrey);
  background-size: 100%;
  transition: background 0s; }

/*Android Theme*/
.ripple-android {
  background-position: center;
  transition: background 0.8s; }

.ripple-android:hover {
  background: rgba(0, 0, 0, 0.1) radial-gradient(circle, transparent 1%, rgba(81, 81, 81, 0.5) 1%) center/15000%; }

.ripple-android:active {
  background-color: var(--altgrey);
  background-size: 100%;
  transition: background 0s; }
