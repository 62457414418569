* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.App {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #f6f8fa;
  height: 100vh;
  color: #fafafa; }

.App-title {
  font-size: 50px;
  padding: 25px 0; }

.Timers {
  display: flex;
  justify-content: center; }

@media (max-width: 900px) {
  .Timers {
    flex-direction: column;
    align-items: center; } }

@media (max-width: 900px) {
  .Stopwatch {
    margin-bottom: 40px; } }

.Countdown,
:local(.Stopwatch) {
  margin: 2.5% auto;
  padding: 20px;
  width: 400px;
  background-color: #f6f8fa; }

.Countdown {
  padding-top: 10px; }

.Countdown-header,
.Stopwatch-header {
  font-size: 40px;
  font-weight: bold; }

.Stopwatch-display {
  padding: 40px 0;
  font-size: 48px; }

.Countdown-display {
  margin-top: 5px;
  margin-bottom: 20px; }

.Countdown-display button {
  margin: 0 15px;
  border: solid 1px transparent;
  border-radius: 2px;
  padding: 4px 16px;
  color: #ffffff;
  font-size: 16px; }

.Countdown-label {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px; }

.Countdown-time {
  font-size: 36px;
  margin: 5px 0; }
