:local(.container) {
  max-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  :local(.container) > .icon {
    position: absolute;
    right: -50px;
    top: -50px; }
  :local(.container) > .header {
    display: flex;
    justify-content: center;
    align-items: center; }
    :local(.container) > .header > h2 {
      font-weight: normal; }
    :local(.container) > .header > .fa {
      cursor: pointer; }
  :local(.container) > .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%; }
    :local(.container) > .body > select {
      padding-left: 8px; }
    :local(.container) > .body > .buttons {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-between; }
