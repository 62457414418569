:local(.app) {
  width: 175vh;
  padding: 10px;
  position: relative; }
  @media only screen and (max-width: 600px) {
    :local(.app) {
      width: 40vh; } }

:local(.detailsCell) {
  width: 1px;
  display: flex;
  flex-direction: row; }
  :local(.detailsCell) > .button {
    background-color: #a0a9b0;
    min-width: inherit;
    padding: 12px;
    margin: auto 5% auto auto; }
  :local(.detailsCell) :local(.editButton) {
    margin: auto 1% auto auto;
    background-color: #0392f5; }

:local(.select) {
  display: flex;
  justify-content: center;
  min-width: fit-content; }

:local(.head) {
  overflow: scroll; }
  :local(.head) > th {
    margin: auto; }

:local(.map) > .email {
  overflow-x: auto; }

:local(.map) > td {
  padding: 15px;
  overflow-y: auto;
  overflow-x: auto; }

:local(.map) > img {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem; }

:local(.map) > Icon {
  display: flex;
  justify-content: flex-end; }
