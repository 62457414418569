.app {
  text-align: center; }

.app-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.app-header {
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40rem;
  margin: -20vh;
  color: white;
  font-size: 4rem;
  box-sizing: border-box; }

.component {
  font-size: 2rem; }

html {
  font-size: 10px; }

body {
  width: 100%;
  font-family: "adelle", Georgia, serif;
  font-size: 1rem;
  margin: auto; }

main > div {
  background-color: #eee; }

h2 {
  margin: 0; }

.flex {
  display: flex;
  position: relative; }

.flex-item {
  flex: 1; }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-item:not(:last-child) {
  margin-right: 2rem; }

.max-width {
  max-width: 720px;
  margin: 0 auto; }

.lr-inset {
  width: 90%;
  margin: 0 auto; }

.lr-outset {
  width: 105%;
  max-width: 100vw;
  box-sizing: border-box;
  padding: 2rem;
  align-self: center; }

.overlap-bottom {
  margin-bottom: -15px; }

.tb-padding-1 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.tb-padding-2 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.padding-1 {
  padding: 1rem; }

.padding-2 {
  padding: 2rem; }

p {
  margin: 0;
  line-height: 1.5em;
  font-size: 1.5rem; }

a {
  color: #66ab45; }

.nav-tab {
  color: rgba(255, 255, 255, 0.5);
  flex: 1; }

.top-inner-shadow {
  box-shadow: inset 0 7px 10px -5px #aaa; }

.bottom-inner-shadow {
  box-shadow: inset 0 -7px 10px -5px #aaa; }

.tb-inner-shadow {
  box-shadow: inset 0 -7px 10px -5px #aaa, inset 0 7px 10px -5px #aaa; }

.table {
  width: 100%;
  border-collapse: collapse; }

.table tr {
  display: flex; }

.table td,
.table th {
  flex: 1;
  padding: 10px; }

.table td {
  font-size: 1.5rem; }

.table td:not(:last-child) {
  border-right: 1px solid #ccc; }

.column span {
  font-size: 1.1em;
  display: block; }

.column:not(:last-child) {
  border-right: 2px dotted #66ab45; }

.content-after-included:after {
  content: "included";
  display: block; }

.content-after-turnaround:after {
  content: "# of graphs";
  display: block; }

.content-after-support:after {
  /* content: "included support"; */
  display: block; }

[class*='content-after']:after {
  font-size: .9em;
  color: #66ab45; }

.col_line {
  width: 2px;
  float: left; }

.nav-tab, #showcase {
  font-size: 2.4rem;
  padding: 20px 5px;
  font-weight: bold;
  text-align: center; }

.nav-tab.active {
  text-shadow: 1px 1px #66ab45;
  color: #fff; }

.selected-indicator {
  background-color: #66ab45;
  height: 5px;
  position: absolute;
  left: 0;
  transition: left 200ms; }

.bg-green {
  background-color: #66ab45; }

.text-green {
  color: #66ab45; }

.bg-dark {
  background-color: #333; }

.reverse {
  color: #fff; }

.feature {
  font-style: italic;
  font-weight: 400;
  padding: 5px 0; }

.table tr:not(:last-child) .feature {
  border-bottom: 1px dotted white; }

.table .price {
  font-size: 2rem;
  font-weight: bold;
  color: #66ab45;
  text-shadow: 1px 1px #fff; }

.plan_name {
  border-bottom: 1px solid #ccc; }

.subtext, .disclaim {
  font-size: .9em;
  line-height: .9em; }

.strong {
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center; }

.small {
  font-size: .9em;
  line-height: 1em;
  padding: 5px 0;
  color: #66ab45;
  text-shadow: 1px 1px #fff;
  font-weight: 700; }

.expansion p {
  color: #999;
  font-size: .8em;
  padding-bottom: 10px; }

.expansion .title {
  font-size: 18px;
  color: #66ab45;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

li {
  font-style: italic;
  font-size: initial;
  text-align: initial;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding: 1rem 0; }

.expansion {
  display: none; }

li {
  color: silver; }

.expandable {
  transition: flex 200ms;
  padding-bottom: 0; }

.expandable.active {
  overflow: hidden; }

.expandable.active .expansion {
  display: initial; }

.expansion-icon {
  padding-left: .5rem;
  cursor: pointer; }

.highlight {
  background-color: #ddd; }

.dimmed {
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.5; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
