#main-nav {
  /* width: 100%; */
  height: 55px;
  background-color: white !important;
  -webkit-box-shadow: 0px 10px 13px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 13px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 13px -11px rgba(0, 0, 0, 0.75);
padding: 0px;
padding-right: 10px;
}

#nav-bar-logo {
  height: 40px;
  margin-left: 10px;
  margin-top: 5px;
  padding: 0px
}
#logo-anchor{
  padding: 0px;
  margin: 0px;
  /* float: left; */
}
#logout-navbar-nav{
  /* background-color: red; */
  width: 10%;
  background-color: white;
  /* margin: 10px; */
  

}
#toggle-switch{
  margin-left: 20px;
  height: 50px;
  /* margin-top: 20px; */
  float: right;
  /* padding-top: 20px; */
  display: inline-block;
  padding-top: 15px;
}
.navbar-right-content{
  text-decoration: none;
  color: black;
  margin-right:30px; 
  margin: 10px; 
}
.navBar-username {
  float: right;
  margin-right: 30px;
}

