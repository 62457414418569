.call-modal {
  position: absolute;
  width: 400px;
  padding: 20px;
  left: calc(50vw - 200px);
  top: calc(50vh - 60px);
  background: -webkit-linear-gradient(to bottom, #074055 0%, #030D10 100%);
  background: -moz-linear-gradient(to bottom, #074055 0%, #030D10 100%);
  background: -o-linear-gradient(to bottom, #074055 0%, #030D10 100%);
  background: linear-gradient(to bottom, #074055 0%, #030D10 100%);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  display: none; }
  .call-modal.active {
    display: block;
    z-index: 9999; }
  .call-modal .btn-action:not(.hangup) {
    background-color: #7FBA00; }
  .call-modal span.caller {
    color: #00AFF0; }
  .call-modal p {
    font-size: 1.5em; }

@-webkit-keyframes blinking {
  25% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes blinking {
  25% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes blinking {
  25% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes blinking {
  25% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  75% {
    -webkit-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
