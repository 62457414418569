:local(.app) {
  min-height: 50%;
  width: 100%; }

:local(.app) .main {
  min-height: 50%;
  width: 100%;
  margin: 25% auto; }

:local(.app) body,
:local(.app) input,
:local(.app) button {
  font-size: 1.6vw;
  margin: 0;
  font-family: Sans-Serif;
  height: 100%;
  width: auto;
  margin: auto; }

@media (max-width: 1024px) {
  :local(.app) body,
  :local(.app) input,
  :local(.app) button {
    font-size: 1em; } }

:local(.app) input:focus,
:local(.app) button:focus {
  outline: 0; }

:local(.app) input[type=text] {
  border: none;
  border-bottom: solid 2px #4c4c4f;
  font-size: 1em;
  background-color: transparent;
  color: #444;
  padding: .4em 0;
  margin: 2ex 0;
  width: 100%;
  max-width: 18em;
  display: block; }

:local(.app) .home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  margin: auto; }
  :local(.app) .home > h1 {
    margin: auto; }

:local(.app) .call-exit-button,
:local(.app) .hangup-button,
:local(.app) .audio-button-true,
:local(.app) .audio-button-false,
:local(.app) .video-button-true,
:local(.app) .video-button-false,
:local(.app) .fullscreen-button {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: rgba(44, 44, 44, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  box-shadow: .2ex .2ex 1.5em #444;
  transition: all .2s ease-out;
  margin: 0 .4em;
  cursor: pointer; }

:local(.app) .call-exit-button:hover,
:local(.app) .hangup-button:hover,
:local(.app) .audio-button-true:hover,
:local(.app) .audio-button-false:hover,
:local(.app) .video-button-true:hover,
:local(.app) .video-button-false:hover,
:local(.app) .fullscreen-button:hover {
  box-shadow: .4ex .4ex 3em #666;
  background: #407cf7; }

:local(.app) .you-left,
:local(.app) .remote-left,
:local(.app) .hangup-button,
:local(.app) .audio-button-true .on,
:local(.app) .video-button-true .on,
:local(.app) .video-button-false .off,
:local(.app) .fullscreen-button .on,
:local(.app) .room-occupied {
  display: none; }

:local(.app) :-webkit-full-screen .fullscreen-button .on {
  display: block; }

:local(.app) :-moz-full-screen .fullscreen-button .on {
  display: block; }

:local(.app) :-ms-fullscreen .fullscreen-button .on {
  display: block; }

:local(.app) :fullscreen .fullscreen-button .on {
  /* spec */
  display: block; }

:local(.app) :-webkit-full-screen .fullscreen-button .off {
  display: none; }

:local(.app) :-moz-full-screen .fullscreen-button .off {
  display: none; }

:local(.app) :-ms-fullscreen .fullscreen-button .off {
  display: none; }

:local(.app) :fullscreen .fullscreen-button .off {
  /* spec */
  display: none; }

/* deeper elements */
:local(.app) :-webkit-full-screen video {
  width: 100%;
  height: 100%; }

:local(.app) .guest-hangup + .auth .you-left {
  display: inline; }

:local(.app) .full .room-occupied,
:local(.app) .host-hangup + .auth .remote-left,
:local(.app) .recent-room {
  display: block; }

:local(.app) .recent-room {
  color: #4285F4;
  margin: 1ex 1em; }

:local(.app) .remote-video {
  display: block;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1); }

:local(.app) .local-video {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  transition: all .3s; }

:local(.app) .established .local-video {
  margin: 10px;
  max-height: 17%;
  max-width: 17%;
  height: auto;
  width: auto; }

:local(.app) .media-bridge,
:local(.app) .local-video,
:local(.app) .remote-video {
  position: absolute;
  height: auto;
  width: auto;
  margin: auto; }

:local(.app) .auth {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

:local(.app) .primary-button {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-color: #4285F4;
  border: none;
  color: white;
  font-size: 0.8em;
  margin: 0 5px 20px 5px;
  width: 8em;
  line-height: 3ex;
  padding: 1ex 0.7em;
  text-align: center;
  -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5); }

:local(.app) .media-controls {
  display: flex;
  margin: 2ex 0;
  justify-content: center;
  left: 0;
  right: 0; }

:local(.app) .svg {
  width: 1.4em; }

:local(.app) .room-occupied,
:local(.app) .request-access,
:local(.app) .waiting,
:local(.app) .request-access,
:local(.app) .grant-access,
:local(.app) .waiting {
  overflow: hidden;
  padding: 0 1em;
  box-sizing: border-box;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  /* Initially we don't want any height, and we want the contents to be hidden */
  max-height: 0;
  transition: max-height .3s ease; }

:local(.app) .full + .auth .room-occupied,
:local(.app) .guest-hangup + .auth .request-access,
:local(.app) .join + .auth .request-access,
:local(.app) .approve + .auth .grant-access {
  transition-delay: .3s;
  /* Set the max-height to something large. In this case there's an obvious limit. */
  max-height: 20ex; }

:local(.app) .host-hangup + .auth .waiting,
:local(.app) .create + .auth .waiting {
  /* Set the max-height to something large. In this case there's an obvious limit. */
  max-height: 20ex; }

:local(.app) .established + .auth .hangup-button {
  display: flex; }
