.component-display {
  background-color: #f1f3f4;
  color: #000a6c;
  text-align: right;
  font-weight: 200;
  flex: 0 0 auto;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.component-display > div {
  font-size: 2.5rem;
  padding: 0.2rem 0.7rem 0.1rem 0.5rem;
}
