.component-button {
  display: inline-flex;
  width: 25%;
  flex: 1 0 auto;
}

.component-button.wide {
  width: 50%;
}

.component-button button {
  background-color: #c4f1ff;
  color: #000a6c;
  border: 0;
  font-size: 1.5rem;
  flex: 1 0 auto;
  padding: 0;
}

.component-button:last-child button {
  margin-right: 0;
}

.component-button.orange button {
  background-color: #000a6c;
  color: white;
}
