:local(.body) > .switch-buffer {
  height: 60px; }

:local(.body) > .switch-table > .main {
  border: none;
  width: 100%; }

:local(.icons) {
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: auto 87.5%; }
  :local(.icons) > .group {
    font-size: 18px;
    font-weight: lighter;
    padding: 0px 10px;
    color: #aaa; }

:local(.contactInfo) {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 0;
  padding: 0 10px; }
  :local(.contactInfo) > div {
    min-height: 36px;
    display: flex;
    align-items: center; }
    :local(.contactInfo) > div:not(:last-child) {
      margin-bottom: 5px; }
    :local(.contactInfo) > div input, :local(.contactInfo) > div span, :local(.contactInfo) > div button {
      margin-left: 5px; }
