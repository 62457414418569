.component-app {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  height: 500px;
  width: 500px;
  margin-top: 50px;
  font-family: "Roboto", sans-serif;
  /*font-family: "Noto Sans", sans-serif;*/
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.4);
}
