:local(.app) {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  align-items: center; }
  @media screen and (min-width: 1200px) {
    :local(.app) {
      max-width: 1100px; } }
  :local(.app) > .main {
    width: 100%;
    overflow-y: scroll; }
