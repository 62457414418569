:root {
  /*--primary: hsl(180, 29%, 50%);*/
  /*--dark-primary: hsl(180, 14%, 20%);*/
  /*--desaturated: hsl(180, 8%, 52%);*/
  /*--light-primary: hsl(180, 52%, 96%);*/
  /*--shadow: rgba(89, 163, 163, .5);*/
  /*--primary: #5D5D81;*/
  /*--dark-primary: #3B3355;*/
  /*--desaturated: #BFCDE0;*/
  /*--light-primary: #eeedfe;*/
  /*--shadow: rgba(93, 93, 129, 0.54);*/ }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Spartan', Helvetica, sans-serif;
  transition: all 300ms ease-out; }

body, .main-container {
  font-size: 15px;
  background: var(--light-primary);
  min-height: 100vh; }

.page {
  align-self: center;
  max-width: 1200px;
  width: 100%;
  padding: 32px;
  display: flex;
  justify-content: center; }

.row {
  display: flex;
  align-items: center;
  margin-bottom: 12px; }

.main-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }

.theme-button {
  background: var(--light-primary);
  color: var(--primary);
  border: none;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px black;
  cursor: pointer; }

.theme-button:focus {
  outline: none; }

.jobs-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  padding-top: 15px; }

.job-card {
  display: flex;
  padding: 24px;
  margin: 16px 0px;
  border-radius: 8px;
  background: var(--card-bg);
  box-shadow: 0px 2px 10px 4px var(--shadow); }

.featured-job {
  border-left: 5px solid var(--primary); }

.highlight {
  background: var(--primary);
  padding: 4px 8px;
  padding-top: 7px;
  border-radius: 12px;
  font-size: 8px;
  color: white;
  font-weight: bold;
  margin-left: 10px; }

.featured {
  background: var(--dark-primary); }

.company-name {
  font-size: 14px;
  color: var(--primary);
  font-weight: bold; }

.position {
  font-size: 18px;
  color: var(--dark-primary);
  margin-bottom: 12px; }

.position:hover {
  color: var(--primary);
  cursor: pointer; }

.secondary {
  display: flex;
  font-size: 15px;
  color: var(--desaturated);
  font-weight: 600; }

.secondary p {
  margin-right: 12px; }

.details {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.tags {
  display: flex;
  list-style: none;
  align-items: center; }

.tags li {
  background: var(--light-primary);
  border-radius: 6px;
  display: flex;
  padding: 8px;
  font-size: 13px;
  font-weight: 700;
  margin-right: 12px;
  color: var(--primary); }

.tags li:hover {
  cursor: pointer;
  background: var(--primary);
  color: white; }

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px; }

.logo img {
  /*width: 80%;*/ }

.filter-card {
  background: var(--card-bg);
  padding: 12px 32px;
  border-radius: 8px;
  box-shadow: 0 10px 25px -10px var(--shadow);
  display: flex;
  position: absolute;
  top: -72px;
  min-height: 75px;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  justify-content: space-between;
  /*transition: transform .3s ease-in;*/
  /*transform: scale(0.5);*/
  /*transform: scale(1);*/
  animation: zoomIn 0.5s;
  animation-timing-function: ease-in;
  transition: 1s ease-in-out; }

@keyframes zoomIn {
  from {
    transform: scale(0.5); }
  to {
    transform: scale(1); } }

.filter-chip {
  list-style: none;
  border-radius: 6px;
  color: var(--primary);
  margin-right: 12px;
  background: var(--light-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: bold; }

.filter-name {
  padding: 6px 0 6px 8px; }

.remove-button {
  margin-left: 12px;
  background: var(--primary);
  color: white;
  font-weight: bold;
  padding: 6px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.remove-button:hover {
  cursor: pointer;
  background: var(--dark-primary); }

.filters {
  display: flex; }

.clear {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary); }

.clear:hover {
  color: var(--dark-primary); }

@media screen and (max-width: 420px) {
  .details {
    flex-direction: column; }
  .tags {
    border-top: 1px solid var(--desaturated);
    margin-top: 12px;
    padding-top: 12px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .jobs-list {
    padding-top: 64px; }
  .job-card {
    position: relative;
    padding: 24px 12px;
    margin: 0;
    margin-bottom: 52px; }
  .logo {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: -25px; }
  .logo img {
    width: 50%; }
  .row {
    margin-bottom: 0; }
  .tags li {
    font-size: 14px; }
  .company-name {
    margin: 12px 0; }
  .secondary {
    font-size: 12px;
    color: var(--desaturated);
    font-weight: normal; }
  .filter-card {
    padding: 14px;
    display: flex;
    align-items: center; }
  .filters {
    flex-wrap: wrap; }
  .filter-chip {
    margin: 0 6px 12px 0; } }
