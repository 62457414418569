:local(.body) > .form > .file-upload label {
  padding: 12px 18px; }

:local(.body) > .form > .buttons > .button {
  min-width: 125px; }

:local(.photoList) {
  margin: 10px 0px; }
  :local(.photoList) > .photo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0px 8px; }
