:local(.float) {
  display: flex;
  height: auto;
  margin: 50px auto;
  text-align: center; }

:local(.card) .Business-card .Basic-info .Contact-info {
  text-align: left;
  margin: auto 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
