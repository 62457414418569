body,
html,
#root {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  height: 100%;
  margin: 0;
  padding: 0; }

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0; }

:local(.container) {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 75%; }

:local(.floatbox) {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 75%; }

:local(.title) {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 auto; }

:local(.btnReg) {
  margin-top: 10px; }

:local(.space) {
  display: block;
  height: 1vh;
  width: 100%; }

:local(.plans) {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  justify-content: center;
  align-content: center; }

:local(.plan), #plan {
  display: flex;
  justify-content: center;
  align-items: center; }
  :local(.plan) button, #plan button {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    text-align: center;
    margin: auto;
    border: none;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15); }
    :local(.plan) button p, #plan button p {
      font-size: 10px; }
    :local(.plan) button:hover, #plan button:hover {
      background: #f2f2f2;
      background: linear-gradient(135deg, #f2f2f2 0%, #cbcbcb 50%, white 100%); }
  :local(.plan) img, #plan img {
    grid-area: image;
    justify-self: end;
    width: auto;
    height: 200px; }
