:local(.switchContainer) {
  border: 1px solid #dfe1e2;
  position: relative; }
  :local(.switchContainer).centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto; }
  :local(.switchContainer) > .main {
    background-color: #fff;
    border-top: 1px solid #dfe1e2; }
