:local(.body) {
  margin: auto 10%; }
  :local(.body) > .graph {
    height: 200px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 #afafc5; }
  :local(.body) > .cards {
    display: flex;
    justify-content: space-between; }
    :local(.body) > .cards > .card {
      box-shadow: 0 1px 2px 0 #afafc5;
      background-color: #fff;
      border-radius: 5px;
      width: 140px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      :local(.body) > .cards > .card .title {
        font-size: 10px; }
    :local(.body) > .cards > .card-container {
      padding: 25px;
      flex: 1; }
      :local(.body) > .cards > .card-container > h2 {
        width: 95%;
        margin: 0 auto;
        font-weight: normal; }
      :local(.body) > .cards > .card-container .table > tbody > tr > td > div {
        display: inline-block;
        vertical-align: middle; }
      :local(.body) > .cards > .card-container .table > tbody > tr > td > .supervisor {
        display: inline-flex;
        justify-content: space-between;
        vertical-align: middle; }
        :local(.body) > .cards > .card-container .table > tbody > tr > td > .supervisor > .name {
          display: block; }
        :local(.body) > .cards > .card-container .table > tbody > tr > td > .supervisor > .group {
          color: #8c969f; }
