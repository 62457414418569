:local(.note) {
  display: flex;
  position: relative; }
  :local(.note) > .date-container {
    width: 60px;
    padding: 20px;
    border-right: 1.5px solid #eee; }
    :local(.note) > .date-container > .date {
      width: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      :local(.note) > .date-container > .date > .day {
        font-weight: bold; }
  :local(.note) > .dot {
    background-color: #fff;
    border-radius: 50%;
    padding: 3px;
    border: 4px solid #ff7d38;
    position: absolute;
    top: 28px;
    left: 53px; }
  :local(.note) > .content {
    margin: 20px;
    max-width: 400px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 2px 0 #afafc5; }
    :local(.note) > .content::after {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-right-color: #ff7d38;
      border-width: 8px;
      top: 27px;
      left: 65px; }
    :local(.note) > .content > .title {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      background-color: #ff7d38; }
      :local(.note) > .content > .title > span > span.fa {
        margin-right: 6px; }
    :local(.note) > .content > .body {
      padding: 12px;
      color: #000; }
  :local(.note).visit > .dot {
    border-color: #a0a9b0; }
  :local(.note).visit > .content::after {
    border-right-color: #a0a9b0; }
  :local(.note).visit > .content > .title {
    background-color: #a0a9b0; }
  :local(.note).visit > .content > .body > span {
    display: block;
    color: #666; }
    :local(.note).visit > .content > .body > span > .fa {
      text-align: center;
      padding: 5px;
      font-size: 20px;
      width: 30px; }
  :local(.note).visit > .button {
    height: 100%;
    align-self: center; }
