:local(.container) {
  width: 95%;
  height: 140px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  background-color: #edf0f2; }
  :local(.container) > div {
    padding: 10px;
    padding-top: 34px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    :local(.container) > div.main {
      margin: auto 75px;
      flex: 1;
      border-right: 1px solid #dfe1e2; }
      :local(.container) > div.main > div:not(.last-child) {
        margin-right: 10px; }
    :local(.container) > div.action {
      text-align: center;
      margin: auto 50px; }
  @media (max-width: 768px) {
    :local(.container) {
      margin: auto 15%; } }
