:local(.app) {
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  align-items: center; }
  @media screen and (min-width: 1200px) {
    :local(.app) {
      max-width: 1100px; } }
  :local(.app) > .main {
    width: 100%;
    overflow-y: scroll; }

:local(.notifications) {
  width: 50px;
  color: blue;
  position: absolute; }

/* Footer Styles */
.footer.visible {
  transform: translateY(0);
  /* Slide up when visible */ }

.footer {
  position: sticky;
  width: 100%;
  border-top: 10px solid #7FBA00;
  /* Reserve space for the curve */
  border-image: radial-gradient(rgba(107, 80, 80, 0.5), rgba(255, 255, 255, 0.5)) 30;
  background: radial-gradient(ellipse at top, rgba(0, 255, 0, 0.5) 1%, rgba(255, 255, 255, 0) 50%);
  transition: transform 2.5s ease;
  /* Smooth slide-up effect */
  transform: translateY(200%);
  /* Initially hidden */
  color: #7FBA00;
  margin: 1% auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly; }
  .footer .footer-container {
    display: grid;
    gap: 2.5%;
    padding: auto 5%;
    align-items: center;
    grid-template-columns: 50% 50%;
    margin-left: 15%; }
  .footer .footer-section p {
    max-width: 35%;
    margin: 1% 0;
    font-size: 10px;
    color: #bbb; }
  .footer .footer-section .company-name {
    font-weight: bold;
    font-size: 16px;
    color: #7FBA00;
    text-shadow: 0 0 5px #7FBA00; }
  .footer .footer-section .footer-highlight {
    color: #00AFF0;
    font-weight: bold;
    text-shadow: 0 0 5px #00AFF0; }
  .footer .footer-section .footer-key {
    color: #E81123;
    font-weight: bold;
    text-shadow: 0 0 5px #E81123; }
  .footer .footer-section .server-time {
    font-family: 'Fira Code', monospace;
    font-size: 14px;
    color: #7FBA00;
    text-shadow: 0 0 5px #7FBA00;
    display: flex;
    align-items: center; }
    .footer .footer-section .server-time .server-time-label {
      font-weight: bold;
      margin-right: 5px;
      color: #666;
      text-shadow: 0 0 5px #666; }
    .footer .footer-section .server-time .server-time-value {
      font-weight: bold;
      color: #666;
      text-shadow: 0 0 5px #666; }
    @media (max-width: 768px) {
      .footer .footer-section .server-time {
        font-size: 12px;
        flex-direction: column;
        text-align: center; }
        .footer .footer-section .server-time .server-time-label {
          margin: auto; } }
  .footer .footer-section .network-status {
    display: flex;
    align-items: center;
    font-family: 'Fira Code', monospace;
    font-size: 14px;
    color: #666; }
    .footer .footer-section .network-status .status-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
      box-shadow: 0 0 5px; }
    .footer .footer-section .network-status .online {
      background-color: #00ff00;
      box-shadow: 0 0 10px #00ff00; }
    .footer .footer-section .network-status .offline {
      background-color: #ff0000;
      box-shadow: 0 0 10px #ff0000; }
    .footer .footer-section .network-status .status-label {
      font-weight: bold;
      text-shadow: 0 0 5px #aaa; }
  @media (max-width: 768px) {
    .footer {
      overflow-y: scroll;
      padding: auto;
      margin: auto; }
      .footer .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: auto;
        margin: auto 2.5%; }
      .footer .footer-section {
        padding: 1;
        margin: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between; } }
