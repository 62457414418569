:local(.dashboardTab) {
  padding: 10px; }
  :local(.dashboardTab) .expenses .header {
    padding: 0px; }
  :local(.dashboardTab) .expenses .actions {
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    :local(.dashboardTab) .expenses .actions div {
      display: flex;
      align-items: center; }
      :local(.dashboardTab) .expenses .actions div div, :local(.dashboardTab) .expenses .actions div > .button {
        margin-right: 5px; }
      :local(.dashboardTab) .expenses .actions div .button {
        min-width: auto; }
      :local(.dashboardTab) .expenses .actions div .select {
        width: 200px; }
      :local(.dashboardTab) .expenses .actions div .status {
        white-space: pre;
        margin: 10%;
        color: black;
        text-decoration: underline;
        font-weight: bolder; }
    @media screen and (max-width: 1200px) {
      :local(.dashboardTab) .expenses .actions {
        flex-direction: column;
        margin: auto 15%; }
        :local(.dashboardTab) .expenses .actions .select {
          justify-content: center;
          align-items: center; }
        :local(.dashboardTab) .expenses .actions .button {
          margin: 5%;
          min-width: auto; } }
