.container-drag {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: auto; }

.wip {
  display: flex;
  flex-direction: row;
  width: 150px;
  min-height: 50%;
  max-height: 90%;
  margin: 5vh 5vh;
  left: 0;
  top: 10;
  background-color: #eeeeee;
  border-right: 1px dotted;
  border-radius: 5px; }

.tasks {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  max-height: 90%;
  margin: 5vh 10%;
  padding: 0 5vh;
  top: 10;
  background-color: #eeeeee;
  border-right: 1px dotted;
  border-radius: 5px; }

.droppable {
  position: absolute;
  width: 150px;
  min-height: 50%;
  max-height: 90%;
  margin: 5vh 5vh;
  right: 0;
  top: 10;
  background-color: #9e9e9e;
  border-left: 1px dotted;
  border-radius: 5px; }

.header {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #e0e0e0;
  width: 100%; }

.task-header {
  display: inline-block;
  background-color: transparent;
  width: 100%;
  height: 10%; }

.draggable {
  width: 100px;
  height: 100px;
  background-color: yellow;
  margin: 5px auto;
  line-height: 5em; }
