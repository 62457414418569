:local(.container) {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%; }
  :local(.container) Form {
    display: flex; }
    :local(.container) Form Input {
      margin-bottom: 5px;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border: none; }

:local(.btnReg) {
  margin-top: 20px; }

:local(.body) {
  width: 100%;
  flex: 1;
  display: flex; }
  :local(.body) > .button {
    width: 100%; }
