/* html,
body,
#root {
  height: 100%;
} */
body {
  /* background-color: white;  */
}

#outer-main-div {
  width: 100%;
  height: 100vh;
}
#outer-nav {
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 1;
}

#main-non-nav {
  height: 100%;
  background-color: white;
}

#sidebar {
  transition: all 0.1s linear;
  background-color: white;
  width: 240px;
  height: 100%;
  float: left;
  overflow: auto;
  -moz-box-shadow:    0 0 5px #666;
  -webkit-box-shadow: 0 0 5px #666;
  box-shadow:         0 0 5px #666;
  /* padding-top: 60px; */
  /* margin-top: 60px; */
  /* display: block; */
  /* z-index: 2; */
}
.display-none{
  width: 0px !important;
}
.display-block{
  width: 240px !important;
}
#sidebar::-webkit-scrollbar { 
  width: 0 !important;
}
#sidebar-top-content {
  padding-top: 55px;
}
#main-title {
  font-size: 20px;
  margin: 40px 40px 30px 20px;
  /* margin-right:1000px;  */
  color: #666;
}
#main-area {
  /* background-color: red; */
  height: 100%;
  overflow-y: auto;
  /* margin-left: 240px; */
}
.sidebar-icon {
  margin-right: 10px;
  color: #666;
}
.navbar-ul li a {
  text-decoration: none;
  color: #666;
}
.navbar-ul li {
  list-style-type: none;
  margin-left: 10px;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  #sidebar {
    width: auto;
    width: 70px;
  }
  .display-block{
    width: 70px !important;
  }
  #main-title {
    /* margin-left: 10px; */
    /* color: red; */
     margin: 5px;
  }
  .navbar-ul li {
    list-style-type: none;
    margin-left: -40px;
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
  }
  .navbar-ul{
    /* padding: 0px; */
  }
  .sidebar-icon {
    display: block;
    /* margin-bottom: 10px; */
    margin: auto;
    /* size: 300px; */
    /* scale: 2; */
    height: 25px;
      width: 25px !important;
  }
  #main-area {
    /* margin-left: 70px; */
  }
}
