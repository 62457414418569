:local(.modal) {
  display: flex;
  justify-content: center;
  text-align: center;
  overflow-y: scroll;
  padding: 20vh; }

:local(.main) {
  margin: 3vh 3vh;
  width: 400px;
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif; }

:local(.submit) {
  padding: 5px 10px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #ddd23b;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  transition: all 0.5s;
  cursor: pointer; }

:local(.submit:active) {
  background-color: #9e8825;
  box-shadow: 0 5px #666;
  transform: translateY(4px); }

:local(.submit span) {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s; }

:local(.submit:hover span) {
  padding-right: 25px; }

:local(.submit span:after) {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s; }

:local(.submit:hover span:after) {
  opacity: 1;
  right: 0; }

a {
  color: #00B7FF; }

:local(.formGroup) {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; }

label {
  display: block;
  font-weight: bold; }

input {
  width: 75px; }
