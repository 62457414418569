:local(.calendar) {
  width: 100%;
  min-height: 300px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  border-bottom: none;
  box-shadow: 0 1px 2px 0 #afafc5;
  margin-bottom: 42px; }
  :local(.calendar) > .weekdays {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    :local(.calendar) > .weekdays > .day {
      flex: 1;
      text-align: center;
      padding: 10px;
      color: #aaa; }
  :local(.calendar) > .weeks {
    border: 1px solid #eee; }
    :local(.calendar) > .weeks > .week {
      display: flex; }
      :local(.calendar) > .weeks > .week:not(:last-child) {
        border-bottom: 1px solid #eee; }
