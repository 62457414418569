:local(.button2) {
  position: absolute;
  top: 0%;
  right: 8.2%;
  font-family: "Roboto", sans-serif;
  text-align: center;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: rgba(235, 30, 30, 0.75);
  box-shadow: inset 0px 50px 0px -20px rgba(30, 235, 43, 0.6);
  padding: 15px 20px;
  font-size: 14px;
  max-width: 50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 150ms; }
  @media only screen and (max-width: 600px) {
    :local(.button2) {
      padding: 12px 6px; } }
  :local(.button2) :focus {
    border: none;
    outline: none;
    box-decoration-break: none; }
  :local(.button2):hover {
    background-color: rgba(24, 189, 35, 0.6);
    box-shadow: inset 0px 50px 0px -20px rgba(235, 30, 30, 0.75); }
  :local(.button2).wide {
    width: 100%;
    border-radius: 0px; }
  :local(.button2).large {
    padding: 14px 24px; }
    @media only screen and (max-width: 600px) {
      :local(.button2).large {
        padding: 14px 12px; } }
  :local(.button2).rx {
    background: lightgrey;
    color: #888888;
    font-size: 1em; }
  :local(.button2).cancel {
    color: #888;
    background: #fff;
    border: 1px solid #eee;
    width: 100px; }
  :local(.button2).search {
    color: #ff7d38;
    background-color: #fff;
    min-width: inherit;
    height: 36px;
    border: 1px solid #dfe1e2; }
  :local(.button2).danger {
    background-color: #ef4836; }
  :local(.button2).confirm {
    background-color: #0392f5; }
  :local(.button2).inactive {
    width: auto;
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5; }
    :local(.button2).inactive:hover {
      box-shadow: none; }
  :local(.button2) > .fa {
    margin-right: 10px; }

:local(.textButton) {
  cursor: pointer;
  transition: all 150ms; }
  :local(.textButton):hover {
    text-shadow: 1px 1px 8px #b4b3b3; }
  :local(.textButton).danger {
    color: #ef4836; }
  :local(.textButton).confirm {
    color: #0392f5; }
