.main-window {
  display: flex;
  margin: 3rem auto;
  height: min-content;
  font-size: 1.75em;
  background-color: #fafbfc;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .main-window {
      padding-top: 40px; }
      .main-window .pull-left, .main-window .pull-right {
        width: 100%;
        text-align: center; }
      .main-window .pull-right {
        margin-top: 20px; } }
  .main-window .btn-action {
    height: 60px;
    width: 60px;
    margin: 20px 30px 0px 0px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    border: solid 2px #FFFFFF;
    cursor: pointer;
    transition-duration: 0.25s;
    background-color: #89ceffb6; }
    .main-window .btn-action:hover {
      background-color: rgba(0, 0, 0, 0.2); }
  .main-window .txt-clientId {
    height: 40px;
    margin: 40px auto 0px 10px;
    color: #666;
    font-size: 0.9em;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #FFFFFF; }
    .main-window .txt-clientId::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.8); }
    .main-window .txt-clientId::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.8); }
    .main-window .txt-clientId:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.8); }
  .main-window .btngrp {
    display: flex;
    flex-direction: row;
    padding: 10px; }
