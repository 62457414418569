:local(.container) {
  display: flex;
  justify-content: center;
  align-items: center; }

:local(.box) {
  height: 70%; }

:local(.body) {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  :local(.body) > .button {
    width: 100%;
    margin-top: 5px; }

.forgotPasswordLabel {
  margin: 0px auto;
  font-family: "Open Sans", sans-serif; }

.claimAccount {
  font-family: "Open Sans", sans-serif;
  color: #ff7d38; }
  .claimAccount:hover {
    text-decoration: underline; }

:local(.separate) {
  height: 15px; }

:local(.title) {
  display: flex;
  justify-content: center;
  margin: auto; }
