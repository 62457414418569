:local(.container) {
  font-family: "Roboto", sans-serif;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 70%; }
  :local(.container) > div > p {
    text-align: center; }
  :local(.container) > div > .body {
    flex: 1;
    justify-content: space-around; }
    :local(.container) > div > .body > .error {
      color: red;
      margin-left: 10px; }
    :local(.container) > div > .body > .button {
      width: 200px; }
