.dropdown {
  display: table-row;
  justify-content: space-between;
  position: absolute;
  top: 9%;
  right: 1%;
  width: 200px;
  padding: 5px 15px;
  min-height: auto;
  border-radius: 2.5%;
  background: radial-gradient(circle, #0a130b 0%, white 0%, #dfe1e2 100%);
  box-shadow: 5px 5px 0px 5px rgba(252, 252, 252, 0.151);
  transition: height 200ms ease-in;
  overflow: scroll;
  -webkit-transition: height 200ms ease-in;
  -moz-transition: height 200ms ease-in;
  -o-transition: height 200ms ease-in;
  transition: height 200ms ease-in; }

.name {
  display: flex;
  justify-content: flex-start;
  font-family: 'Economica', sans-serif;
  margin: auto;
  padding: 15px 0 0 0;
  font-size: 15px;
  color: black; }

.option {
  height: 20px; }
  .option a {
    color: #666;
    text-decoration: none; }
  .option:hover {
    background: radial-gradient(circle, rgba(167, 186, 214, 0.15) 60%, rgba(188, 210, 236, 0.1) 100%, rgba(148, 188, 233, 0.185) 50%); }

.options {
  height: 20px;
  font-family: 'Alegreya SC', serif;
  margin: 0;
  padding: 1px;
  text-align: center; }
  .options a {
    margin: auto;
    color: #666;
    text-decoration: none; }
  .options:hover {
    background: radial-gradient(circle, rgba(167, 186, 214, 0.15) 60%, rgba(188, 210, 236, 0.1) 100%, rgba(148, 188, 233, 0.185) 50%); }
  .options > .brand {
    display: flex;
    justify-content: center;
    margin: 0 0 5px auto; }

img {
  height: 5rem; }

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center; }
