:local(.portfolioTab) {
  padding: 10px; }
  @media only screen and (max-width: 600px) {
    :local(.portfolioTab) {
      width: 40vh; } }
  :local(.portfolioTab) > .expenses > .header {
    padding: 0px; }
  :local(.portfolioTab) > .expenses > .actions {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 600px) {
      :local(.portfolioTab) > .expenses > .actions {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 5%; } }
    :local(.portfolioTab) > .expenses > .actions > div {
      display: flex;
      align-items: center; }
      :local(.portfolioTab) > .expenses > .actions > div > div, :local(.portfolioTab) > .expenses > .actions > div > .button {
        margin-right: 5px; }
      :local(.portfolioTab) > .expenses > .actions > div .button {
        min-width: auto; }
      :local(.portfolioTab) > .expenses > .actions > div .select {
        width: 200px; }
