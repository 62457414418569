:local(.buttonGroup) {
  box-shadow: 0 1px 2px 0 #afafc5;
  border-radius: 5px; }
  :local(.buttonGroup) > button {
    background-color: #fff;
    background: #fff;
    padding: 10px 14px;
    border-color: transparent;
    color: #888;
    font-size: 12px; }
    :local(.buttonGroup) > button:hover {
      background-color: #eee; }
    :local(.buttonGroup) > button.selected {
      background-color: #a0a9b0;
      color: #fff; }
    :local(.buttonGroup) > button:not(:first-child) {
      border-left: none; }
    :local(.buttonGroup) > button:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    :local(.buttonGroup) > button:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
