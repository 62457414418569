:local(.body) {
  margin: 5rem auto;
  padding: auto 5rem 5rem 5rem;
  height: fit-content;
  width: fit-content; }

:local(.title) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large; }

:local(.buttons) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem auto; }

:local(.logo) {
  margin: 2rem 10rem; }

img {
  height: 10rem;
  margin: 1rem 0rem; }
