:local(.sideBar) {
  height: 100%; }
  :local(.sideBar) > .body {
    box-shadow: 0 1px 2px 0 #afafc5;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 360px;
    height: calc(100% - 106.51px);
    max-height: 778.68px;
    background-color: #fff;
    position: absolute;
    top: 106.51px; }
    :local(.sideBar) > .body.flipped {
      left: 0px; }
    :local(.sideBar) > .body:not(.flipped) {
      right: 0px; }
    @media screen and (min-width: 1200px) {
      :local(.sideBar) > .body.flipped {
        left: inherit;
        right: calc(50% + 190px); }
      :local(.sideBar) > .body:not(.flipped) {
        right: inherit;
        left: calc(50% + 190px); } }
    :local(.sideBar) > .body > .header {
      background-color: #f6f8fa;
      padding: 20px; }
      :local(.sideBar) > .body > .header > h2 {
        margin: 0;
        font-weight: normal;
        color: #ff7d38; }
      :local(.sideBar) > .body > .header > span {
        display: block; }
        :local(.sideBar) > .body > .header > span.label {
          margin-top: 20px;
          font-weight: bold; }
      :local(.sideBar) > .body > .header > .icon {
        position: absolute;
        top: 10px;
        right: 10px; }
    :local(.sideBar) > .body > .visits {
      flex: 1;
      overflow-y: scroll; }
      :local(.sideBar) > .body > .visits > .visit > .supervisor {
        padding: 10px;
        padding-left: 20px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee; }
        :local(.sideBar) > .body > .visits > .visit > .supervisor > .line {
          margin: 10px 0; }
          :local(.sideBar) > .body > .visits > .visit > .supervisor > .line > span {
            display: block; }
            :local(.sideBar) > .body > .visits > .visit > .supervisor > .line > span.name {
              font-size: 20px;
              color: #000; }
            :local(.sideBar) > .body > .visits > .visit > .supervisor > .line > span > .fa {
              margin-right: 10px; }
      :local(.sideBar) > .body > .visits > .visit > .notes > .title {
        padding: 10px;
        padding-left: 20px;
        background-color: #f6f8fa; }
    :local(.sideBar) > .body > button > .fa {
      margin-right: 10px; }
